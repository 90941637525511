<template>
    <section :class="[disabled && 'disabled', error && 'error', [(loading || componentLoader) && 'loading']]">
        <form autocomplete="off">
            <label>{{ title }}</label>
            <div class="flex relative container flex-row curser-pointer">
                <div class="input" :class="show ? 'bg-unfocus' : 'bg-focus'">
                    <svg class="search" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7204 9.49402H10.0429L9.80274 9.26246C10.6432 8.28476 11.1492 7.01546 11.1492 5.63467C11.1492 2.55577 8.65352 0.0600586 5.57461 0.0600586C2.49571 0.0600586 0 2.55577 0 5.63467C0 8.71357 2.49571 11.2093 5.57461 11.2093C6.9554 11.2093 8.2247 10.7033 9.2024 9.8628L9.43396 10.1029V10.7805L13.7221 15.0601L15 13.7822L10.7204 9.49402ZM5.57461 9.49402C3.43911 9.49402 1.71527 7.77018 1.71527 5.63467C1.71527 3.49917 3.43911 1.77532 5.57461 1.77532C7.71012 1.77532 9.43396 3.49917 9.43396 5.63467C9.43396 7.77018 7.71012 9.49402 5.57461 9.49402Z" fill="#ACACAC"/>
</svg>

                    <input v-model.trim="text" @focus="error = false" :required="!!required" :placeholder="placeholder" @input="searchInput" @blur="checkExist" />
                    <div :class="show && options.length > 0 && 'show'">
                    </div>
                        <svg class="loader" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-outside-1_1114_2217" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17" fill="black">
                                <rect fill="white" width="17" height="17" />
                                <path
                                    d="M1 8.5C1 12.6421 4.35786 16 8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5ZM14.4241 8.5C14.4241 11.7718 11.7718 14.4241 8.5 14.4241C5.22823 14.4241 2.57593 11.7718 2.57593 8.5C2.57593 5.22823 5.22823 2.57593 8.5 2.57593C11.7718 2.57593 14.4241 5.22823 14.4241 8.5Z"
                                />
                            </mask>
                            <path
                                d="M1 8.5C1 12.6421 4.35786 16 8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5ZM14.4241 8.5C14.4241 11.7718 11.7718 14.4241 8.5 14.4241C5.22823 14.4241 2.57593 11.7718 2.57593 8.5C2.57593 5.22823 5.22823 2.57593 8.5 2.57593C11.7718 2.57593 14.4241 5.22823 14.4241 8.5Z"
                                fill="#1D9BF0"
                                fill-opacity="0.25"
                            />
                            <path
                                d="M1 8.5C1 12.6421 4.35786 16 8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5ZM14.4241 8.5C14.4241 11.7718 11.7718 14.4241 8.5 14.4241C5.22823 14.4241 2.57593 11.7718 2.57593 8.5C2.57593 5.22823 5.22823 2.57593 8.5 2.57593C11.7718 2.57593 14.4241 5.22823 14.4241 8.5Z"
                                stroke="#BDDCF1"
                                stroke-width="1.2"
                                mask="url(#path-1-outside-1_1114_2217)"
                            />
                            <mask id="path-2-outside-2_1114_2217" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10" fill="black">
                                <rect fill="white" width="10" height="10" />
                                <path
                                    d="M1 8.5C1 7.51509 1.19399 6.53982 1.5709 5.62987C1.94781 4.71993 2.50026 3.89314 3.1967 3.1967C3.89314 2.50026 4.71993 1.94781 5.62988 1.5709C6.53982 1.19399 7.51509 1 8.5 1L8.5 2.57593C7.72204 2.57593 6.9517 2.72916 6.23296 3.02687C5.51422 3.32458 4.86115 3.76095 4.31105 4.31105C3.76095 4.86115 3.32458 5.51421 3.02687 6.23296C2.72916 6.9517 2.57593 7.72204 2.57593 8.5H1Z"
                                />
                            </mask>
                            <path
                                d="M1 8.5C1 7.51509 1.19399 6.53982 1.5709 5.62987C1.94781 4.71993 2.50026 3.89314 3.1967 3.1967C3.89314 2.50026 4.71993 1.94781 5.62988 1.5709C6.53982 1.19399 7.51509 1 8.5 1L8.5 2.57593C7.72204 2.57593 6.9517 2.72916 6.23296 3.02687C5.51422 3.32458 4.86115 3.76095 4.31105 4.31105C3.76095 4.86115 3.32458 5.51421 3.02687 6.23296C2.72916 6.9517 2.57593 7.72204 2.57593 8.5H1Z"
                                fill="#047FFF"
                            />
                            <path
                                d="M1 8.5C1 7.51509 1.19399 6.53982 1.5709 5.62987C1.94781 4.71993 2.50026 3.89314 3.1967 3.1967C3.89314 2.50026 4.71993 1.94781 5.62988 1.5709C6.53982 1.19399 7.51509 1 8.5 1L8.5 2.57593C7.72204 2.57593 6.9517 2.72916 6.23296 3.02687C5.51422 3.32458 4.86115 3.76095 4.31105 4.31105C3.76095 4.86115 3.32458 5.51421 3.02687 6.23296C2.72916 6.9517 2.57593 7.72204 2.57593 8.5H1Z"
                                stroke="#047FFF"
                                stroke-width="1.2"
                                mask="url(#path-2-outside-2_1114_2217)"
                            />
                        </svg>
                </div>
                <!-- <div ></div> -->
                <aside class="options" v-if="show && filterableOption.length > 0" v-click-outside="hideOptions">
                    <template v-for="(option, i) in filterableOption" :key="i">
                        <a href="#" class="option" @click.left.prevent="select($event, option)"  v-show="!('show' in option) || option.show" :class="modelValue.value === option.value && 'active'">
                                {{ option.title }}
                        </a>
                    </template>
                </aside>
            </div>
        </form>
    </section>


</template>

<script>
/**
 * option {Object} = object, value, title
 * value - Used for unique identification for row
 * title - Used for title of row
 */
export default {
    props: ['modelValue', 'title', 'options', 'disabled', 'required', 'placeholder', 'loading'],
    data() {
        return {
            error: false,
            show: false,
            filterableOption: [],
            text: '',
            debounce: null,
            componentLoader: false
        };
    },

    watch: {
        options: {
            handler(val) {
                this.filterableOption = val;
            },
            immediate: true,
        },
    },
    methods: {
        hideOptions() {
            this.show = false;
        },
        select(event, { title, object }) {
            this.$emit('selectOption', object);
            this.text = title;
            this.show = false;
            this.error = false;
            clearTimeout(this.debounce);
        },
        checkExist() {
            setTimeout(() => {
                const exist = this.options.find((item) => item.value === this.modelValue.value);
                if (!exist && this.text) {
                    if(this.text.length > 2) {
                       this.searchInput();
                    }
                    this.error = true;
                } else {
                    this.show = false;
                    this.error = false;
                }
            }, 500);
        },
        searchInput(e) {
             this.show = true;
             this.error = false;
             clearTimeout(this.debounce);

             if(this.text && this.text.length > 2) {
                 this.componentLoader = true

                 this.debounce = setTimeout(async () => {
                      this.$emit('search', this.text);
                      this.componentLoader = false
                 }, 600);
             } else {
                  this.componentLoader = false
             }
        },
    },
};
</script>

<style scoped lang="scss">
$inputPlaceHolder: rgba(57, 57, 57, 0.5);
$borderColor: #878787;
$textColor: #3f3f3f;
$inputFocus: rgb(4, 127, 255);
$textRed: #dd2c01;
$inputBg: rgba(241, 241, 241, 1);
$inputBgFocus: rgba(249, 249, 249, 1);

section {
    position: relative;
    text-align: left;
    color: rgba(62, 62, 62, 1);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    label {
        line-height: 18px;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }

    .container {
        width: 100%;
        height: 41.18px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        border-radius: 4px;
        .input {
            display: flex;
            align-items: center;
            background: transparent;
            width: 100%;
            border: none;
            text-align: left;
          
            font-weight: 500;
            user-select: none;
            border-radius: 4px;
            cursor: pointer;
        }
        input {
            font-size: 14px;
            width: 100%;
            height: 41.18px;
            padding-left: 34px;
            padding-right: 34px;
            background: transparent;
            resize: none;
            outline: none;
             & + div {
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
                 position: absolute;
                border: 1px solid $borderColor;
                 border-radius: 4px;
                  pointer-events: none;
             }
             &:focus + div{
                position: absolute;
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
                pointer-events: none;
                border: 2px solid $inputFocus;
                border-radius: 4px;
            }
        }
        svg {
            pointer-events: none;
            position: absolute;
            cursor: pointer;
        }
        svg.search {
            left: 10px;
        }

        svg.loader {
            right: 11px;
        }

        .show {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    .bg-focus {
        background: $inputBg !important;
    }
    .bg-unfocus {
        background: $inputBgFocus !important;
    }

    .options {
        z-index: 3;
        position: absolute;
        top: 41.18px;
        max-height: 177px;
        overflow-y: auto;
        border-top: none !important;
        width: 100%;

        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        border-bottom: 0.5px solid #8a898a !important;
        border-left: 0.5px solid #8a898a !important;
        border-right: 0.5px solid #8a898a !important;

        .option {
            // height: 33.38px;
            padding-top: 5px;
            padding-bottom: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 13.42px;
            background: white;
            border-top: 0.5px dashed #cecece;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                background: #047fff;
                color: white;
            }
            &:active {
                background: #0070e4;
                color: white;
            }
        }
        .option:first-child {
            border-top: none;
        }
        .option:last-child {
            padding-bottom: 10px;
        }
        .option.active {
            background: #0070e4;
            color: white;
        }
    }
    @keyframes rotate {
        from {
            transform: rotate(-360deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .loader {
        visibility: hidden;
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

section.disabled {
    * {
        pointer-events: none;
    }
    .input {
        background: rgba(232, 232, 232, 1) !important;
    }
    input {
        pointer-events: none;
        & + div{
           border-color: transparent !important;
        }
    }
  
}

section.error {
    input + div {
        border: 2px solid $textRed !important;
    }
}

section.loading {
   
    svg.loader {
      visibility: visible;
    }
       
}
</style>
