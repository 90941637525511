<template>
    <Modal type="New Policy" @submitData="submitData" />
</template>
<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },

    methods: {
        async submitData() {
            const { fullName, company }  = await this.$store.dispatch('policies/createTableRow', this.$store.state.policies.modal.details);
            this.hideModals();
            this.$store.commit('policies/resetSearch');
            this.$store.commit('policies/setSearch', {fullName, company});
            this.$router.push({ name: 'policies', hash: '#'+this.hash() })
        },
    },
};
</script>
