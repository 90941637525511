<template>
    <SearchableServerSide v-model="option" :disabled="disabled" :loading="loading" @selectOption="select" title="Edit Customer" placeholder="Type Name" @search="search" :options="options" />
</template>

<script>
import SearchableServerSide from '@/components/SearchableServerSide';
export default {
    components: {SearchableServerSide},
    props: ['disabled'],
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        options() {
            const list = this.$store.state.customers.customerList.map((item) => {
                return {
                    title: item.fullName,
                    value: item.id,
                    object: item,
                };
            });
            return list;
        },

        modal() {
            return this.$store.state.policies.modal.details;
        },

        option: {
            get() {
                return {
                    title: this.modal.fullName,
                    value: this.modal.customerId,
                    object: this.modal,
                };
            },
            set(v) {
                this.$store.commit('policies/setDetails', {fullName: v});
            },
        },
    },

    methods: {
        select({id: customerId, fullName, company, address}) {
            this.loading = false;
            this.$store.commit('policies/setDetails', {customerId, fullName, company, address});
        },
        async search(fullName) {
            const emptyData = () => {
                this.$store.commit('customers/setCustomerList', []);
            };

            if (fullName && fullName.length >= 3) {
                this.loading = true;
                const hasRows = await this.$store.dispatch('customers/customersList', {fullName});
                if (!hasRows) {
                    // If none is fetched, empty customerId
                    emptyData();
                }
                this.loading = false;
            } else {
                emptyData();
            }
        },
    },
};
</script>
