<template>
    <section class="modal whitelist-clickoutside" v-esc="hideModals">
        <form
            class="container"
            :class="isDelete && 'delete'"
            @submit.prevent="submitData"
            @keydown="
                (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }
            "
            style="width: 958px"
        >
            <div class="header flex items-center justify-center text-white">
                <h4 class="font-bold">{{ type }}</h4>
                <a @click.prevent="hideModals" class="x-button">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.77932 12.2984C7.72583 14.3649 5.7198 16.3838 3.72239 18.3985C2.70859 17.3804 1.71205 16.3795 0.715501 15.3743C2.70428 13.3769 4.71894 11.3623 6.73792 9.33467C4.70168 7.29844 2.68702 5.27946 0.685303 3.27343C1.6991 2.25531 2.69565 1.25445 3.71808 0.227705C5.7198 2.24668 7.72583 4.26997 9.75775 6.31483C11.8069 4.25272 13.813 2.22943 15.8147 0.210449C16.8457 1.25014 17.8423 2.251 18.8388 3.25617C16.8544 5.24926 14.8397 7.26824 12.8423 9.27427C14.8699 11.3062 16.8759 13.3209 18.869 15.3226C17.8423 16.3493 16.8371 17.3502 15.8147 18.3769C13.8345 16.3881 11.8285 14.3649 9.77932 12.2984Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </div>

            <div class="content">
                <div class="grid grid-cols-4 gap-x-5 gap-y-8">
                    <div :class="type !== 'Edit Policy' && 'hidden'">
                        <SearchableUsers :disabled="isDelete" />
                    </div>

                    <div class="input-container">
                        <label> Name </label>
                        <input :value="modal.fullName" disabled />
                    </div>

                    <div class="input-container">
                        <label> Company </label>
                        <input :value="modal.company" disabled />
                    </div>

                    <div class="input-container">
                        <label> Address </label>
                        <input :value="modal.address" disabled />
                    </div>
                </div>

                <div class="hr mt-8"></div>

                <div class="grid grid-cols-4 gap-x-5 gap-y-8 pt-6">
                    <CheckBox v-model="modal.newBusiness" title="New Business" :disabled="isDelete" />
                    <Date v-model="effective" title="Effective" :required="true" :disabled="isDelete" />
                    <Date v-model="modal.expires" title="Expires" :required="true" :disabled="isDelete" />
                </div>

                <div class="grid grid-cols-4 gap-x-5 gap-y-8 pt-5">
                    <template v-if="type !== 'New Policy'">
                        <Date v-model="cancelled" title="Cancelled" :disabled="isDelete" />
                    </template>
                    <SearchableCarrier :disabled="isDelete" ref="searchablecarrier" :required="true" />
                    <Select class="max-height-1000px" v-model="modal.type" :options="types" title="Type" :required="true" :disabled="isDelete" />

                    <template v-if="type !== 'New Policy'">
                        <CheckBoxOptional v-model="modal.renewed" title="Renewed" :required="true" :disabled="isDelete" />
                    </template>
                </div>

                <div class="grid pt-5 grid-cols-2 gap-x-5 gap-y-8">
                    <div class="w-full">
                        <div class="input-container">
                            <label> Notes</label>
                            <textarea
                                :readonly="isDelete"
                                style="height: 103.64px"
                                rows="3"
                                v-model.trim="modal.notes"
                                v-on:keyup.enter="
                                    (e) => {
                                        e.target.value += '\n';
                                    }
                                "
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="hr mt-8"></div>

                <div class="grid grid-cols-4 gap-x-5 gap-y-8 pt-5">
                    <Money v-model.trim="modal.premium" title="Premium" :disabled="isDelete" :required="true" ref="premium" />
                    <template v-if="modal.newBusiness === false">
                        <Money v-model.trim="modal.oldPremium" title="Old Premium" :disabled="isDelete" />
                    </template>
                    <template v-if="modal.newBusiness === false">
                        <Percent v-model="adjusted" title="Adjusted" :disabled="true" />
                    </template>

                    <Percent v-model="modal.commissionRate" title="Commission Rate" :disabled="isDelete" />
                </div>

                <div class="buttons flex justify-center flex-row pt-12">
                    <button class="save w-full" type="submit"><span>Save</span></button>
                    <button class="cancel w-full" type="button" @click.prevent="hideModals">Cancel</button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import Date from '../../../components/Date';
import Select from '../../../components/Select';
import Money from '../../../components/Money';
import Percent from '../../../components/Percent';
import CheckBox from '../../../components/CheckBox';
import CheckBoxOptional from '../../../components/CheckBoxOptional';
import SearchableUsers from './SearchableUsers';
import SearchableCarrier from './SearchableCarrier';

export default {
    props: {
        type: String,
        defaultdata: Object,
        isDelete: {
            type: Boolean,
            default: false,
        },
    },

    components: {Date, CheckBox, Money, Percent, Select, CheckBoxOptional, SearchableUsers, SearchableCarrier},

    computed: {
        modal() {
            return this.$store.state.policies.modal.details;
        },
        customersList() {
            return this.$store.state.customers.customerList;
        },
        types() {
            return this.$store.getters['policies/getTypes'](this.modal);
        },

        cancelled: {
            get() {
                return this.modal.cancelled;
            },
            set(v) {
                this.$store.commit('policies/setDetails', {cancelled: v});
                if (this.testDate(v)) {
                   this.$store.commit('policies/setDetails', {renewed: false});
                } else if(v === null && this.modal.renewed === false) {
                   this.$store.commit('policies/setDetails', {renewed: null});
               }
            },
        },

        effective: {
            get() {
                return this.modal.effective;
            },
            set(v) {
                this.$store.commit('policies/setDetails', {effective: v});
                if (this.testDate(v)) {
                    const expires = this.addOneYear(v);
                    if (!this.expires && expires) {
                        this.$store.commit('policies/setDetails', {expires});
                    }
                }
            },
        },

        adjusted() {
            let percent;

            if (!this.modal.oldPremium || !this.modal.premium) {
                return;
            }

            let oldPremium = this.modal.oldPremium.toString().replace(/[^0-9.]/g, '');
            let premium = this.modal.premium.toString().replace(/[^0-9.]/g, '');
            if (!oldPremium || !premium) {
                return;
            }

            if (oldPremium === '0' || premium === '0') {
                return;
            }

             if (parseFloat(premium) === parseFloat(oldPremium)) {
                return '0';
            }

            percent = ((premium - oldPremium) * 100) / oldPremium;
            percent = Math.round((percent + Number.EPSILON) * 100) / 100;

            return percent;
        },
    },

    mounted() {
        if (this.defaultdata) {
            this.modal = this.defaultdata;
        }
    },

    methods: {
        submitData(e) {
            e.preventDefault();

            // TO-DO June 30, 2022. Comment or remove this lines to fix error
            // if(this.customersList.length > 0) {
            //    const valid = this.customersList.find(item=>item.id === this.modal.customerId);
            //     if(!valid ) {
            //         return
            //     }
            // }

            if (this.$refs['searchablecarrier'].loading) {
                return;
            }

            if (!this.modal.customerId) {
                return;
            }

            if (!/\d\d?\/\d\d?\/\d{4}$/g.test(this.effective)) {
                return;
            }

            if (!/\d\d?\/\d\d?\/\d{4}$/g.test(this.modal.expires)) {
                return;
            }

            this.$emit('submitData');
        },
    },
};
</script>

<style scoped lang="scss">
.hr {
    width: 100%;
    border-top: 1px dashed #e3e3e3;
}
</style>
